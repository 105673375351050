import React, { useState, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import SignaturePad from "react-signature-canvas";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  FormControl,
  FormLabel,
  Textarea,
  Input,
  Button,
  HStack,
  VStack,
  CheckboxGroup,
  Checkbox,
  Flex,
  Text,
  Spinner,
  Icon,
  Link,
  Radio,
  RadioGroup
} from "@chakra-ui/react";

import { BsXCircle } from "react-icons/bs";
import { VscWarning } from "react-icons/vsc";

const schema = yup.object().shape({
  applicantName: yup.string().required("Applicant name is required"),
  phone: yup
  .string()
  .required()
  .matches(
    /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
    "Invalid phone number"
  ),
  mailingAddress: yup.string().required("Mailing address is required"),
  email: yup
    .string()
    .required('Email is required')
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Invalid email"
    ),
  businessName: yup.string().required("Business name is required"),
  nameEvent: yup.string().required("Name of event is required"),
  typeEvent: yup.string().required("Type of event is required"),
  numberParticipants: yup.string().required("Number of participants is required"),
  numberEvents: yup.string().required("Number of events is required"),
  venueLocation: yup.array().min(1, "At least one option is required").nullable(),
  servingBeerWine: yup.string().required("Serving beer/wine is required").nullable(),
  retailSalesAtEvent: yup.string().required("Retail sales at event is required").nullable(),
  recyclingEvent: yup.string().required("Recycling plans is required"),
  applicantSignature: yup
    .string()
    .required("Signature is required.")
    .matches(
      /^data:image\/(?:gif|png|jpeg|bmp|webp|svg\+xml)(?:;charset=utf-8)?;base64,(?:[A-Za-z0-9]|[+/])+={0,2}/,
      "Signature must be png"
    ),
  date: yup.string().required("Date is required"),
  time: yup.string().required("Time is required"),
});


const BldGresFormUpdated = () => {
  const defaultValues = {
    venueLocation: []
  };

  const {
    register,
    handleSubmit,
    watch,
    control,
    reset,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const [succeeded, setSucceeded] = useState(false);
  const [processing, setProcessing] = useState(false);

  const watchData = watch();

  // create a Ref to access our form element
  const formRef = useRef(null);

  const FormSuccess = () => (
    <Box my="20">
    <Alert status="success"
      variant="subtle"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      p="4"
      >
    <AlertIcon boxSize='40px' mr={0}/>
      <AlertTitle mb="4">
        Application submitted successfully!
        </AlertTitle>
        <AlertDescription maxWidth='md'>
        Thank you for submitting your application. We will be in touch with you shortly.<br /><br />Please check your email for a copy of your application for your records.
  </AlertDescription>
    </Alert>
  </Box>
   )

  const sendMailgunFormData = async (data) => {
    //event.preventDefault()
    setProcessing(true);

    if (!formRef.current) {
        console.log('something went wrong with the ref');
    }

    const formData = new FormData(formRef.current);
    
    // convert signature to blob
    const applicantSignatureBlob = await (await fetch(watchData.applicantSignature)).blob([applicantSignatureBlob], "applicantSignature.png");

    // append blob to formData
    formData.append("applicantSignature", applicantSignatureBlob, "applicantSignature.png");

    const convertArrayToObject = (array, key) => {
        const initialValue = {};
        return array.reduce((obj, item) => {
          return {
            ...obj,
            [item[key]]: item,
          };
        }, initialValue);
      };

    // Convert license array to object - parsing function only returns the last value
    convertArrayToObject(watchData.venueLocation, "venueLocations");


    formData.append('venueLocations', JSON.stringify(watchData.venueLocation));


    // Use console.log() to see the data that is being sent to the server
    console.log(Array.from(formData.entries()));

   fetch('/.netlify/functions/send-city-center-reservation', {
           method: 'POST',
           body: formData,
           // don't send headers due to boundary problem 
       })
       // Convering the response to JSON breaks status code
       .then(res => {
           console.log(res)
              if (res.status === 200) {
                    setSucceeded(true)
                    setProcessing(false)
                    reset()
                    scrollTop()
                } else {
                    setSucceeded(false)
                    console.log("network error")
                    alert("Network error. Please try again later.")
                }
       }
    )
};

  {
    /* Signature Pad */
  }
  let sigCanvas = useRef({});

  const formatIntoPng = () => {
    if (sigCanvas.current) {
      const dataURL = sigCanvas.current.toDataURL();
      return dataURL;
    }
  };

  const clear = () => sigCanvas.current.clear();

  function RequiredSpan() {
    return <span style={{ color: "#bf1650" }}>*</span>;
  }
  
  const errorStyles = {
    color: "#bf1650",
    fontSize: "1rem",
  };
  
  const scrollTop = () => {
    window.scrollTo(0, 0);
  };
  
  return (
      <Box id="theForm">
        {succeeded ? (
          <FormSuccess />
        ) : (
          <>
        <Box my="8">
          <Text as="b">
            Please call the City Clerk at{" "}<Link href="tel:3542362">354-2362</Link> for information on facility description, occupancy load and availability.
          </Text>
        </Box>
            <form onSubmit={handleSubmit(sendMailgunFormData)} ref={formRef}>
              <Box my="8">
                <FormLabel>Applicant Name<RequiredSpan /></FormLabel>
                <Input
                  type="text"
                  {...register("applicantName")}
                  autoComplete="on"
                />
                <p style={errorStyles}>
                  {errors.applicantName && (
                    <HStack spacing="5px">
                      <VscWarning /> <Box>{errors.applicantName?.message}</Box>
                    </HStack>
                  )}
                </p>
              </Box>

              <Flex w="100%" flexWrap="wrap" mb="8">
                <Box w={{ base: "100%", md: "50%" }}>
                  <FormLabel>Phone<RequiredSpan /></FormLabel>
                  <Input
                    type="text"
                    {...register("phone")}
                    autoComplete="on"
                  />
                  <p style={errorStyles}>
                    {errors.phone && (
                      <HStack spacing="5px">
                        <VscWarning /> <Box>{errors.phone?.message}</Box>
                      </HStack>
                    )}
                  </p>
                </Box>
                <Box w={{ base: "100%", md: "50%" }} pl={{ base: 0, md: "4" }}>
                  <FormLabel>Email<RequiredSpan /></FormLabel>
                  <Input
                    type="email"
                    {...register("email")}
                    autoComplete="on"
                  />
                  <p style={errorStyles}>
                    {errors.email && (
                      <HStack spacing="5px">
                        <VscWarning /> <Box>{errors.email?.message}</Box>
                      </HStack>
                    )}
                  </p>
                </Box>
              </Flex>

              <Box mb="8">
                <FormLabel>Mailing Address<RequiredSpan /></FormLabel>
                <Input
                  type="text"
                  {...register("mailingAddress")}
                  autoComplete="on"
                />
                {errors.mailingAddress && (
                  <HStack spacing="5px" style={errorStyles}>
                    <VscWarning /> <Box>{errors.mailingAddress?.message}</Box>
                  </HStack>
                )}
              </Box>

              <Box mb="8">
                <FormLabel>Business/Organization/Sponsor Name<RequiredSpan /> </FormLabel>
                <Input
                  type="text"
                  {...register("businessName")}
                  autoComplete="on"
                />
                {errors.businessName && (
                  <HStack spacing="5px" style={errorStyles}>
                    <VscWarning /> <Box>{errors.businessName?.message}</Box>
                  </HStack>
                )}
              </Box>

              <Box mb="8">
                <FormLabel>Name of Event<RequiredSpan /></FormLabel>
                <Input
                  type="text"
                  {...register("nameEvent")}
                  autoComplete="on"
                />
                {errors.nameEvent && (
                  <HStack spacing="5px" style={errorStyles}>
                    <VscWarning /> <Box>{errors.nameEvent?.message}</Box>
                  </HStack>
                )}
              </Box>

              <Box mb="8">
                <FormLabel>Type or Purpose of Event<RequiredSpan /></FormLabel>
                <Textarea {...register("typeEvent")} autoComplete="on" />
                {errors.typeEvent && (
                  <HStack spacing="5px" style={errorStyles}>
                    <VscWarning /> <Box>{errors.typeEvent?.message}</Box>
                  </HStack>
                )}
              </Box>

              <Flex w="100%" flexWrap="wrap" mb="8">
                <Box w={{ base: "100%", md: "50%" }}>
                  <FormLabel>Number of Participants<RequiredSpan /></FormLabel>
                  <Input
                    type="number"
                    {...register("numberParticipants")}
                    autoComplete="on"
                  />
                  <p style={errorStyles}>
                    {errors.numberParticipants && (
                      <HStack spacing="5px">
                        <VscWarning />{" "}
                        <Box>{errors.numberParticipants?.message}</Box>
                      </HStack>
                    )}
                  </p>
                </Box>
                <Box w={{ base: "100%", md: "50%" }} pl={{ base: 0, md: "4" }}>
                  <FormLabel>Number of Events<RequiredSpan /></FormLabel>
                  <Input
                    type="number"
                    {...register("numberEvents")}
                    autoComplete="on"
                  />
                  <p style={errorStyles}>
                    {errors.numberEvents && (
                      <HStack spacing="5px">
                        <VscWarning /> <Box>{errors.numberEvents?.message}</Box>
                      </HStack>
                    )}
                  </p>
                </Box>
              </Flex>

              <Flex w="100%" flexWrap="wrap" mb="8">
                <Box w={{ base: "100%", md: "50%" }}>
                  <FormLabel>Date(s)<RequiredSpan /></FormLabel>
                  <Input type="date" {...register("date")} autoComplete="on" />
                  <p style={errorStyles}>
                    {errors.date && (
                      <HStack spacing="5px">
                        <VscWarning /> <Box>{errors.date?.message}</Box>
                      </HStack>
                    )}
                  </p>
                </Box>
                <Box w={{ base: "100%", md: "50%" }} pl={{ base: 0, md: "4" }}>
                  <FormLabel>Time:</FormLabel>
                  <Input type="time" {...register("time")} autoComplete="on" />
                  <p style={errorStyles}>
                    {errors.time && (
                      <HStack spacing="5px">
                        <VscWarning /> <Box>{errors.time?.message}</Box>
                      </HStack>
                    )}
                  </p>
                </Box>
              </Flex>

              <Box mb="8">
                <Text>1. Venue Location (select all that apply):</Text>

                <FormControl>
                  <FormLabel as="legend" mb="4">
                    Check all below for which you are applying:
                  </FormLabel>
                  <CheckboxGroup defaultValue="">
                    <VStack
                      align="left"
                      spacing="18px"
                      wrap="true"
                      direction={{ base: "column", md: "column" }}
                    >
                      {/* <Checkbox
                        value="Council Chamber 40"
                        name="venueLocation"
                        {...register("venueLocation")}
                      >
                        Council Chamber &mdash; Only available during regular business hours (Gov, political, school &amp; non-profit)
                        {" "}&mdash;{" "}<strong>$40 per day</strong>
                      </Checkbox>
                      <Checkbox
                        value="Council Council Chamber 80"
                        name="venueLocation"
                        {...register("venueLocation")}
                      >
                        Council Chamber &mdash; Only available during regular business hours (Commercial &amp; for-profit){" "}&mdash;{" "}
                        <strong>$80 per day</strong>
                      </Checkbox>
                      <Checkbox
                        value="Audio Visual Equipment"
                        name="venueLocation"
                        {...register("venueLocation")}
                      >
                        Audio Visual Equipment
                        use{" "}&mdash;{" "}<strong>$25 per day</strong>
                      </Checkbox> */}
                      <Checkbox
                        value="Foyer"
                        name="venueLocation"
                        {...register("venueLocation")}
                      >
                        Foyer
                        {" "}&mdash;{" "}<strong>$25 per day</strong>
                      </Checkbox>
                      <Checkbox
                        value="Plaza"
                        name="venueLocation"
                        {...register("venueLocation")}
                      >
                        Plaza
                        {" "}&mdash;{" "}<strong>$25 per day</strong>
                      </Checkbox>
                      <Checkbox
                        value="Entry key"
                        name="venueLocation"
                        {...register("venueLocation")}
                      >
                        Entry key card if not returned within 3 business days
                        after event{" "}&mdash;{" "}<strong>$50</strong>
                      </Checkbox>
                      <Checkbox
                        value="Cleaning"
                        name="venueLocation"
                        {...register("venueLocation")}
                      >
                        Cleaning fee, as
                        needed{" "}&mdash;{" "}<strong>Actual custodial cost</strong>
                      </Checkbox>
                    </VStack>
                  </CheckboxGroup>
                </FormControl>
                <p style={errorStyles}>
                  {errors.venueLocation && (
                    <HStack spacing="5px">
                      <VscWarning /> <Box> {errors.venueLocation?.message}</Box>{" "}
                    </HStack>
                  )}
                </p>
              </Box>

              {/* <Box mb="8" w={{ base: "100%", md: "50%" }}>
                <FormLabel>Total $</FormLabel>
                <Input type="number" {...register("total")} autoComplete="on" />
                <p style={errorStyles}>
                  {errors.venueLocation && (
                    <HStack spacing="5px">
                      <VscWarning /> <Box> {errors.venueLocation?.message}</Box>{" "}
                    </HStack>
                  )}
                </p>
              </Box> */}

              <Box mb="8">
              <FormControl>
                    <RadioGroup>
                      <VStack align="left" justify="left">
                        <FormLabel mb="0">
                          2. Will you be serving beer and/or wine?
                        </FormLabel>
                        <Radio
                          value="Yes"
                          name="servingBeerWine"
                          {...register("servingBeerWine")}
                        >
                          Yes
                        </Radio>
                        <Radio
                          value="No"
                          name="servingBeerWine"
                          {...register("servingBeerWine")}
                        >
                          No
                        </Radio>
                      </VStack>
                    </RadioGroup>
                  </FormControl>
                <p style={errorStyles}>
                  {errors.servingBeerWine && (
                    <HStack spacing="5px">
                      <VscWarning />{" "}
                      <Box>{errors.servingBeerWine?.message}</Box>
                    </HStack>
                  )}
                </p>
              </Box>


              <Box mb="8">
              <FormControl>
                    <RadioGroup>
                      <VStack align="left" justify="left">
                        <FormLabel mb="0">
                          3. Will you have retail sales at the event?
                        </FormLabel>
                        <Radio
                          value="Yes"
                          name="retailSalesAtEvent"
                          {...register("retailSalesAtEvent")}
                        >
                          Yes
                        </Radio>
                        <Radio
                          value="No"
                          name="retailSalesAtEvent"
                          {...register("retailSalesAtEvent")}
                        >
                          No
                        </Radio>
                      </VStack>
                    </RadioGroup>
                  </FormControl>
                <p style={errorStyles}>
                  {errors.retailSalesAtEvent && (
                    <HStack spacing="5px">
                      <VscWarning />{" "}
                      <Box>{errors.retailSalesAtEvent?.message}</Box>
                    </HStack>
                  )}
                </p>
              </Box>

              <Box mb="8">
                <FormLabel>
                  {" "}
                  Describe your recycling and waste disposal plans for the
                  event:
                </FormLabel>
                <Textarea
                  {...register("recyclingEvent")}
                  autoComplete="on"
                />
                <p style={errorStyles}>
                  {errors.recyclingEvent && (
                    <HStack spacing="5px">
                      <VscWarning /> <Box>{errors.recyclingEvent?.message}</Box>
                    </HStack>
                  )}
                </p>
              </Box>

              <Box mb="8" align="center">
                <Text as="b">
                  I understand and agree to the terms and responsibilities which
                  are outlined in the Driggs Building Use Policy presented to me
                  at the time the application was submitted.
                </Text>
              </Box>

              
                <Box mb="8" w={{ base: "100%", md: "50%" }}>
                  <FormControl isInvalid={!!errors?.applicantSignature?.message}>
                    <FormLabel>Applicant Signature</FormLabel>
                    <Box>
                      <Controller
                        name="applicantSignature"
                        control={control}
                        render={({ field }) => (
                          <SignaturePad
                            ref={sigCanvas}
                            onEnd={() =>
                              field.onChange(
                                formatIntoPng(sigCanvas.current.toDataURL())
                              )
                            }
                            penColor="black"
                            canvasProps={{
                              style: {
                                border: "1px solid #e2e8f0",
                                borderRadius: "0.375rem",
                                width: "100%",
                                backgroundColor: "white",
                              },
                            }}
                          />
                        )}
                      />

                      {errors.applicantSignature && (
                        <HStack spacing="5px" style={errorStyles}>
                          <VscWarning /> <Box>{errors.applicantSignature?.message}</Box>
                        </HStack>
                      )}
                    </Box>
                    <Box>
                      <input
                        type="hidden"
                        value={new Date()}
                        name="timestamp"
                        {...register("timestamp", { required: true })}
                      />
                    </Box>
                  </FormControl>
                  <Box my="2" textAlign="right">
                    <Button size="sm" onClick={clear} colorScheme="red">
                      <Icon as={BsXCircle} mr="2" />
                      Clear Signature
                    </Button>
                  </Box>
                </Box>
                


              <Box my="8">
                <Button type="submit" textAlign="left" disabled={processing}>
                  {processing ? (
                    <Spinner />
                  ) : succeeded ? (
                    "Submitted!"
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Box>
            </form>
          </>
        )}
      </Box>
  );
};

export default BldGresFormUpdated;
